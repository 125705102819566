import { render, staticRenderFns } from "./FreestandingMicrowaveFilters.vue?vue&type=template&id=93994604&scoped=true&"
import script from "./FreestandingMicrowaveFilters.vue?vue&type=script&lang=js&"
export * from "./FreestandingMicrowaveFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93994604",
  null
  
)

export default component.exports